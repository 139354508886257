import React, {useState, useEffect} from "react";
import debounce from "lodash.debounce";

// @ts-ignore
import styles from "./index.module.scss";

declare var window: any;
declare var document: any;

type Props = React.HTMLAttributes<HTMLButtonElement> & {
    top?: number;
    smooth?: boolean;
    svgPath?: string;
    viewBox?: string;
    component?: any;
    getScrollElement?: any;
};


const ScrollToTop = ({
                         top = 20,
                         className = styles["scroll-to-top"],
                         color = "black",
                         smooth = false,
                         getScrollElement = null,

                         component = "",
                         viewBox = "0 0 256 256",
                         svgPath = "M222.138,91.475l-89.6-89.6c-2.5-2.5-6.551-2.5-9.051,0l-89.6,89.6c-2.5,2.5-2.5,6.551,0,9.051s6.744,2.5,9.244,0L122,21.85  V249.6c0,3.535,2.466,6.4,6,6.4s6-2.865,6-6.4V21.85l78.881,78.676c1.25,1.25,2.992,1.875,4.629,1.875s3.326-0.625,4.576-1.875  C224.586,98.025,224.638,93.975,222.138,91.475z",
                         ...props
                     }: Props) => {
    const [visible, setVisible] = useState(false);

    function scrollToTop(smooth: boolean = false) {
        let targetElement = getScrollElement();

        if (targetElement) {
            if (smooth) {
                targetElement.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                targetElement.scrollTop = 0;
            }
        } else {
            if (smooth) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                document.documentElement.scrollTop = 0;
            }
        }
    }

    const onScroll = debounce(() => {
        let targetElement = getScrollElement();

        if (targetElement) {
            setVisible(targetElement.scrollTop > top);
        } else {
            setVisible(document.documentElement.scrollTop > top);
        }
    }, 250);

    useEffect(() => {
        let targetElement = getScrollElement();

        if (targetElement) {
            targetElement.addEventListener("scroll", onScroll);
            return () => targetElement.removeEventListener("scroll", onScroll);
        } else {
            document.addEventListener("scroll", onScroll);
            return () => document.removeEventListener("scroll", onScroll);
        }
    }, []);

    return (
        <>
            {visible && (
                <button
                    //className={className}
                    className={className}
                    onClick={() => scrollToTop(smooth)}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                >
                    {component || (
                        <svg fill={color} viewBox={viewBox}>
                            <path d={svgPath}/>
                        </svg>
                    )}
                </button>
            )}
        </>
    );
};

const areEqual = (prevProps, nextProps) => {
    return true;
}
export default React.memo(ScrollToTop, areEqual) as any;

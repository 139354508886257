import React, {HTMLAttributes} from 'react';
import sharedConfig from "./config";
import ReactGA, {InitializeOptions, Tracker} from 'react-ga';
import {default as lodash} from 'lodash';
import {IonText} from "@ionic/react/dist/types/components/proxies";

///#region Type overrides
declare module "react" {
    interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
        // @ts-ignore
        loading?: "auto" | "eager" | "lazy";
    }

    interface HTMLAttributes<T> {
        oncontextmenu?: string;
        href?: string;
    }
}
///#endregion


declare var window: any;

let shallowEqual: any = null;
{
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    /**
     * inlined Object.is polyfill to avoid requiring consumers ship their own
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
     */
    function is(x, y) {
        // SameValue algorithm
        if (x === y) {
            // Steps 1-5, 7-10
            // Steps 6.b-6.e: +0 != -0
            // Added the nonzero y check to make Flow happy, but it is redundant
            return x !== 0 || y !== 0 || 1 / x === 1 / y;
        } else {
            // Step 6.a: NaN == NaN
            return x !== x && y !== y;
        }
    }

    /**
     * Performs equality by iterating through keys on an object and returning false
     * when any key has values which are not strictly equal between the arguments.
     * Returns true when the values of all keys are strictly equal.
     */
    shallowEqual = function (objA: any, objB: any): boolean {
        if (is(objA, objB)) {
            return true;
        }

        if (
            typeof objA !== 'object' ||
            objA === null ||
            typeof objB !== 'object' ||
            objB === null
        ) {
            return false;
        }

        const keysA = Object.keys(objA);
        const keysB = Object.keys(objB);

        if (keysA.length !== keysB.length) {
            return false;
        }

        // Test for A's keys different from B.
        for (let i = 0; i < keysA.length; i++) {
            if (
                !hasOwnProperty.call(objB, keysA[i]) ||
                !is(objA[keysA[i]], objB[keysA[i]])
            ) {
                return false;
            }
        }

        return true;
    }
}

let util = {
    shallowEqual: shallowEqual,

    scriptFreeStrify: (str: string | null | undefined) => {
        if (str) {
            str = str.replace(/<script>|<\/script>/g, "");
            if (str.length == 0) {
                return null;
            }
        } else {
            return null;
        }
        return str;
    },

    newlineFreeStrify: (str: string | null | undefined) => {
        if (str) {
            str = str.replace(/[\n\t\s]+/g, " ");
            if (str.length == 0) {
                return null;
            }
        } else {
            return null;
        }
        return str;
    },

    randId: () => {
        return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
    },

    commaSep: (num: any) => {
        return (+(num ?? 0)).toLocaleString('en-US', {maximumFractionDigits: 2});
    },
    removeTailingSlash: (item: string) => {
        return item.replace(/\/$/, "");
    },

    nullify: (str: string | null | undefined) => {
        if (str) {
            str = str.replace(/^[\n\t\s]*$/g, "");
            if (str.length == 0) {
                return null;
            }
        } else {
            return null;
        }
        return str;
    },

    sleepAsync: (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    },

    coalesce: (...args) => {
        for (let v of args) {
            if (v === undefined || v === null || v === 0) {
                /* do nothing */
            } else {
                return v; //return first valid item
            }
        }
    },

    ensureDefined: (v) => {
        if (v === undefined) {
            throw v;
        } else {
            return v
        }
    },

    randomStringID: (length, chars) => {
        let result = '';
        if (chars == undefined)
            chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    },

    /**
     * Construct given namespace on variable
     * for example:
     *  namespace(objA, 'propA.propB') ,  objA.propA ,  objA,propB will be created if not already exist
     * @param variable
     * @param ns  namespace path
     */
    namespace: (variable, ns) => {
        let sofar = variable;

        ns.split('.').forEach(function (e, i) {
            if (typeof sofar[e] === "undefined") {
                sofar[e] = {};
            }
            sofar = sofar[e];
        });

        return sofar;
    },


    namespaceSet: (variable, ns, newValue) => {
        let sofar = variable;
        let parts = ns.split('.');

        if (parts.length > 1) {
            let lastPart = parts[parts.length - 1];
            parts.slice(0, -1).forEach(function (e, i) {
                if (typeof sofar[e] === "undefined") {
                    sofar[e] = {};
                }
                sofar = sofar[e];
            });

            if (Object.is(sofar[lastPart], newValue)) {
                return false;
            }
            if (sofar[lastPart] === newValue) {
                return false;
            }
            sofar[lastPart] = newValue;
            return true;
        } else {
            if (Object.is(variable[ns], newValue)) {
                return false;
            }
            if (variable[ns] === newValue) {
                return false;
            }
            return true;
        }
        return false;
    },

    /**
     * Check variable defines given namspace path
     * for example:
     *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
     * @param variable
     * @param ns  namespace path
     */
    exist: (variable, ns) => {
        if (variable === null) {
            return false;
        }
        if (variable === undefined) {
            return false;
        }

        let sofar = variable;

        let splits = ns.split('.');
        for (let i = 0; i < splits.length; ++i) {
            if (sofar === null) {
                return false;
            }
            if (typeof sofar[splits[i]] === "undefined") {
                return false;
            }
            sofar = sofar[splits[i]];
        }
        return true;
    },

    /**
     * Check variable defines given namspace path
     * for example:
     *  exist(objA, 'propA.propB') checkes whether objA.propA.propB defined
     * @param variable
     * @param ns  namespace path
     */
    existGet: (variable, ns, defaultValue = null) => {
        if (variable === null) {
            return defaultValue;
        }
        if (variable === undefined) {
            return defaultValue;
        }

        let sofar = variable;

        let splits = ns.split('.');
        for (let i = 0; i < splits.length; ++i) {
            if (sofar === null) {
                return defaultValue;
            }
            if (typeof sofar[splits[i]] === "undefined") {
                return defaultValue;
            }
            sofar = sofar[splits[i]];
        }
        return sofar;
    },


    isIn: (array, element) => {
        if (array == null) {
            return false;
        }

        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) {
                return true;
            }
        }
        return false;
    },

    isInHead: (array, element) => {
        if (array == null) {
            return false;
        }

        if (array.length > 0) {
            if (array[0] == element) {
                return true;
            }
        }
        return false;
    },

    isInTail: (array, element) => {
        if (array == null) {
            return false;
        }

        if (array.length > 0) {
            if (array[array.length - 1] == element) {
                return true;
            }
        }
        return false;
    },

    /**
     * Remove first occurrence
     * @param array Only array is supported
     * @param element
     */
    removeFirst: (array, element) => {
        if (array === null) {
            return array;
        }

        if (!util.isArray(array)) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] == element) {
                    return array.splice(i, 1);
                }
            }
        } else {
            throw 'unsupported';
        }
    },

    /**
     * Remove and return all occurrences
     * @param array Only array is supported
     * @param element
     */
    remove: (array, element) => {
        if (array === null) {
            return array;
        }

        if (!util.isArray(array)) {
            let removed: object[] = [];

            for (let i = 0; i < array.length;) {

                if (array[i] == element) {
                    removed.push(array.splice(i, 1));
                    continue;
                }

                i++;
            }
            return removed;
        } else {
            throw 'unsupported';
        }
    },

    /**
     * Return true if value is an empty array, or an empty string, return given value otherwise
     * @param value  Array or String
     */
    isEmpty: (value) => {
        if (value == null)
            return null;

        if (util.isArray(value)) {
            if (value.length === 0) {
                return true;
            } else {
                return false;
            }
        }

        if (util.isString(value)) {
            if (value.length === 0) {
                return true;
            } else {
                return false;
            }
        }

        return value;
    },

    isString: (obj) => {
        return (typeof obj === 'string' || obj instanceof String)
    },

    isArray: (obj) => {
        return Array.isArray(obj);
    },

    isObject: (obj) => {
        let type = typeof obj;
        return type === 'function' || type === 'object' && !!obj;
    },

    isArrayOrObject: function (val) {
        return val instanceof Array || val instanceof Object ? true : false;
    },

    /**
     * This does a deep cloned copy without checking for circular references.
     * @param src
     */
    iterationCopy: (src) => {
        let target = {};
        for (let prop in src) {
            if (src.hasOwnProperty(prop)) {
                // if the value is a nested object, recursively copy all it's properties
                if (util.isObject(src[prop])) {
                    target[prop] = util.iterationCopy(src[prop]);
                } else {
                    target[prop] = src[prop];
                }
            }
        }
        return target;
    },

};


var debug = {
    error: (errors) => {
        if (!sharedConfig.isDev()) {
            console.error(errors);
        } else {
            // nothing
        }
    },
    warn: (warning) => {
        if (sharedConfig.isDev()) {
            console.warn(warning);
        } else {
            // nothing
        }
    },
    log: (logs) => {
        if (sharedConfig.isDev()) {
            console.log(logs);
        } else {
            // nothing
        }
    },
};


let Global = Function('return this')();
// //G.test.history = history;
Global.sharedStateManager = sharedConfig;
// if (window) {
//     window.G = Global;
// }

// var _global = (0, eval)('this');
// _global.location = {};
// _global.window.location = {};

let bs = {
    urlifyAsJsx: function (text): any {
        if (text == null) {
            return text;
        }
        const urlRegex = /((?:http|ftp|https):\/\/[\w-]+(?:\.[\w-]+)*(?:[\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?)/g;
        return text.split(urlRegex)
            .map(part => {
                if (part == null) {
                    return null;
                }
                if (part.match(urlRegex)) {
                    return <a href={part} target="_blank" rel="nofollow noopener">{part}</a>;
                }
                return part;
            });
    },

    urlify: function (text): any {
        if (text == null) {
            return text;
        }
        const urlRegex = /((?:http|ftp|https):\/\/[\w-]+(?:\.[\w-]+)*(?:[\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?)/g;
        return text.split(urlRegex)
            .map(part => {
                if (part == null) {
                    return null;
                }
                if (part.match(urlRegex)) {
                    return `<a href="${part}" target="_blank" rel="nofollow" rel="noopener">${part}</a>`
                }
                return part;
            }).join('');
    },

    getCoverList: function (照片組: string) {
        if (照片組 == null)
            return [];

        const re = /\s*(?:,|$)\s*/;
        const photoList = [...((照片組 ?? '') + (",")).split(',').filter(r => r !== '' || null || undefined)];
        return photoList;
    },

    getCoverSafeList: function (照片組: string) {
        if (照片組 == null)
            return [null];

        let photoList: (string | null)[] = bs.getCoverList(照片組);
        if (photoList.length === 0) {
            photoList = [null];
        }
        return photoList;
    },

    identity: function (value) {
        return value;
    },

    isRwdPhone: function () {
        //     $breakpoints: (
        //         'phone': 375px,
        //         'tablet': 768px,
        //         'desktop': 1024px,
        //         'xdesktop': 1440px
        // ) !default;

        // @if ($operator == '>') {
        //     $element: '(min-width: #{$result + $interval})';
        // } @else if ($operator == '<') {
        //     $element: '(max-width: #{$result - $interval})';
        // } @else if ($operator == '>=') {
        //     $element: '(min-width: #{$result})';
        // } @else if ($operator == '<=') {
        //     $element: '(max-width: #{$result})';
        // } @else {
        // @warn '#{$expression} is missing an operator.';
        // }

        // @include media("<tablet") { /* is phone */
        // @include media(">=tablet", "<desktop") { /* is tablet */
        // @include media(">=desktop", "<xdesktop") { /* is desktop */
        // @include media(">=xdesktop") { /* is xdesktop */

        if (typeof window !== "undefined") {
            // browser code
            if (!window?.matchMedia) {
                return false;
            }
            return !!window.matchMedia("(max-width: 767px)").matches;
        }
        return false;
    },

    isRwdTablet: function () {
        if (typeof window !== "undefined") {
            // browser code
            if (!window?.matchMedia) {
                return false;
            }

            return !!window.matchMedia("(min-width: 768px)").matches &&
                !!window.matchMedia("(max-width: 1023px)").matches;
        }
        return false;
    },

    isRwdDesktop: function () {
        if (typeof window !== "undefined") {
            // browser code
            if (!window?.matchMedia) {
                return false;
            }

            return !!window.matchMedia("(min-width: 1024px)").matches &&
                !!window.matchMedia("(max-width: 1439px)").matches;
        }
        return false;
    },

    isRwdXDesktop: function () {
        if (typeof window !== "undefined") {
            // browser code
            return !!window.matchMedia("(min-width: 1440px)").matches;
        }
        return true; // assume largest device if detection not avalaible
    },

    Form: {
        required: value => {
            if (value) {
                if (value === "") {
                    return "此為必填欄位";
                }
            } else {
                return "此為必填欄位";
            }

            return undefined;
        },
        mustBeNumber: value => (isNaN(value) ? "Must be a number" : undefined),
        minValue: min => value =>
            isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`,
        composeValidators: (...validators) => value =>
            validators.reduce((error, validator) => error || validator(value), undefined),
    },

    doGa: function () {
        // 全網 UA-145056278-1
        // 鉅亨號專屬 UA-145056278-17
        // 全網 GTM GTM-T3FF4WB


        if (typeof window !== "undefined") {
            /// browser code
            if (!sharedConfig.isDev()) {
                ReactGA.initialize([{
                    trackingId: 'UA-145056278-1',
                    gaOptions: {
                        name: 'tracker1',
                    }
                },
                    {
                        trackingId: 'UA-145056278-17',
                        gaOptions: {
                            name: 'tracker2',
                        }
                    }], {debug: false, alwaysSendToDefaultTracker: false});
                ReactGA.pageview(window.location.pathname, ['tracker1', 'tracker2']);
            }

            if (sharedConfig.isDev()) {
                ReactGA.initialize([{
                    trackingId: 'UA-145056278-1',
                    gaOptions: {
                        name: 'tracker1',
                    }
                },
                    {
                        trackingId: 'UA-145056278-17',
                        gaOptions: {
                            name: 'tracker2',
                        }
                    }], {debug: true, alwaysSendToDefaultTracker: false});
                ReactGA.pageview(window.location.pathname, ['tracker1', 'tracker2']);
            }
        }

    },


    symbolReplacer: function (strContainsSymbol, isReplaceWithLink = true) {
        {  //regular symbols
            let regex = /[\[]\$([^\]]+)\]\((\S+)[:]([a-zA-Z0-9_.-]+)[:]([a-zA-Z0-9_.-]+)\)/ig;
            let matched;

            while (matched = regex.exec(strContainsSymbol)) {
                let composedUrl = '';

                let name = lodash.unescape(matched[1]);

                if (!isReplaceWithLink) {
                    // do nothing

                } else {

                    let market = matched[2];
                    let code = matched[3];
                    let mType = matched[4];
                    let symbol = `${market}:${code}:${mType}`;

                    //tweak old symbol to new
                    if (market.toUpperCase() == "TFE") {
                        market = "TWF";
                    }
                    if (market.toUpperCase() == "TSE") {
                        market = "TWS";
                    }
                    if (mType.toUpperCase() == "FUTURE") {
                        mType = "FUTURES";
                    }

                    //start parse
                    if (market.toUpperCase() === "TWS") {
                        if (mType.toUpperCase() === "STOCK") { //台股,台股ETF
                            composedUrl = `https://invest.cnyes.com/twstock/${market}/${code}`;
                        } else if (mType.toUpperCase() === "INDEX") { //台灣指數
                            composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/${market}/${code}`;
                        }
                    } else if (market.toUpperCase() === "TWF") { //台灣期貨
                        composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/${market}/${code}`;
                    } else if (market.toUpperCase() === "FX") { //外匯
                        composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/detail/${code}`;
                    } else if (market.toUpperCase() === "USS") {
                        if (mType === "STOCK") { //美股,美股ETF
                            composedUrl = `https://invest.cnyes.com/usstock/detail/${code}`;
                        }

                    } else if (market.toUpperCase() === "GI") { //國際指數
                        composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/${market}/${code}`;
                    } else if (market.toUpperCase() === "GF") { //國際期貨
                        composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/${market}/${code}`;
                    } else if (market.toUpperCase() === "CNS") { //陸股
                        composedUrl = `https://global-stock.cnyes.com/market/${encodeURI(symbol)}`;

                    } else if (market.toUpperCase() === "HKS") {
                        if (mType === "STOCK") { //港股,港股ETF
                            composedUrl = `https://global-stock.cnyes.com/market/${encodeURI(symbol)}`;
                        }

                    } else if (market.toUpperCase() === "CC") { //虛擬貨幣
                        composedUrl = `https://crypto.cnyes.com/${code}`;
                    } else if (market.toUpperCase() === "TWG") { //興櫃
                        composedUrl = `https://invest.cnyes.com/twstock/${market}/${code}`;
                    } else if (market.toUpperCase() === "EOD") { //EOD
                        composedUrl = `https://invest.cnyes.com/${mType.toLowerCase()}/detail/${code}`;
                    }
                }

                if (composedUrl != '') {
                    strContainsSymbol = strContainsSymbol.replace(regex, `<a class="tag" href="${composedUrl}">\$${lodash.escape(name)}</a>`);
                } else {
                    strContainsSymbol = strContainsSymbol.replace(regex, `\$${lodash.escape(name)}`);
                }
            }
        }

        {  //fund symbols
            let regex = /[\[]\$([^\]]+)\]\((\S+)[:][:][:](FUND)\)/ig;
            let matched;

            while (matched = regex.exec(strContainsSymbol)) {
                let composedUrl = '';

                let name = matched[1];

                if (!isReplaceWithLink) {
                    // do nothing

                } else {
                    let displayNameLocal = matched[1];
                    let cnyesId = matched[2];

                    composedUrl = `https://fund.cnyes.com/detail/${encodeURIComponent(displayNameLocal)}/${encodeURIComponent(cnyesId)}`;
                }

                if (composedUrl != '') {
                    strContainsSymbol = strContainsSymbol.replace(matched, `<a class="tag" href="${composedUrl}">\$${lodash.escape(name)}</a>`);
                } else {
                    strContainsSymbol = strContainsSymbol.replace(matched, `\$${lodash.escape(name)}`);
                }
            }
        }

        return strContainsSymbol;
    },
}

export default {
    G: Global,
    _s: sharedConfig,
    _u: util,
    _d: debug,
    _b: bs,
};

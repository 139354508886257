import {IonAvatar, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonList, IonMenuButton, IonNote, IonPage, IonRefresher, IonRefresherContent, IonRouterLink, IonRow, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToolbar, RefresherEventDetail, useIonModal, useIonRouter} from '@ionic/react';
import {capitalize, productInfo} from '../utils';
import {useStoreState} from 'pullstate';

import styles_6_1 from "./Pg_6_1.module.scss";
import {fetch_Pg_6_1, Pg_6_1_Store, push_pg_6_1} from "../store/Pg_6_Store";
import {getPageData, getQuotes} from "../store/Selectors";
import {Fragment, useEffect, useRef, useState} from "react";
import {QuoteItem} from "../components/QuoteItem";
import {ContractItem} from "../components/ContractItem";
import {fetch_pg_1_2} from "../store/Pg_1_Store";
import {bookmark, bookmarkOutline, checkmarkOutline, chevronDownCircleOutline} from "ionicons/icons";
import {TokenStackItem} from "../components/TokenStackItem";
import {useAppUtil, useSideMenuOptions, useSideMenuUpdate} from "../components/sideMenu/SideMenuProvider";
import {tab1SideMenu} from "../components/sideMenu/PageSideMenus";
import {PageHeader} from "./PageHeader";
import {Modal} from "./Modal";
import CustomPage from "./CustomPage";
import {TokenEnwItem} from "../components/TokenEnwItem";
import {TokenEnwEntrustListItem} from "../components/TokenEnwEntrustListItem";
import {useParams} from "react-router";
import {useLoginFields} from "./Pg_A_1";
import {useFormInput, validateForm} from "../_/useForms";
import indexBy from "underscore/modules/indexBy";
import styles_TokenItem from "../components/TokenItem.module.scss";
import {handleCommonFetchErrors, navToPg_2_3, promptBrowserToLogin} from "./Utils";
import {ContractBonusItem} from "../components/ContractBonusItem";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import moment from "moment/moment";
import styles_Common from "./Common.module.scss";
import CustomField from "../components/CustomField";
import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {constCityList, constCitySubList, constStyleList} from "../_/constant";
import {push_pg_2_4} from "../store/Pg_2_Store";
import {AiItem} from "../components/AiItem";
import {Modal_3_1_WriteArticle} from "./Modal_3_1_WriteArticle";
import styles_2_1 from "./Pg_2_1.module.scss";
import {AuthStore} from "../store/Common_Store";


const customSelectContainerOptions_您的寵物名字 = {
    header: '您的寵物名字',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};


const customSelectContainerOptions_選取風格 = {
    header: '選取風格',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};

export const useMyFields = () => {
    return [
        {
            id: "p1",
            label: "您的寵物名子",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "您的寵物名子",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p2",
            label: "選取風格",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "選取風格",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p3",
            label: "做甚麼?",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "做甚麼?",

                },
                state: useFormInput("")
            }
        },
        {
            id: "p4",
            label: "在哪裡?",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "在哪裡?",

                },
                state: useFormInput("")
            }
        },
    ];
}


const Pg_6_1 = (props) => {
    const pageRef = useRef();
    const pageName = "Pg_6_1";
    const pageId = "Pg_6_1";
    const {sideMenuOptions} = props;
    const setSideMenu: any = useSideMenuUpdate();
    const sideMenu: any = useSideMenuOptions();

    const router = useIonRouter();

    ///#region 會員
    const authStore = useStoreState(AuthStore, getPageData);
    const hasAuthed = authStore?.signBy ?? false;
    //const Item_會員 = hasAuthed ? authStore?.AuthData : undefined;
    ///#endregion

    useEffect(() => {
        setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    }, []);

    //#region Data Loading
    useEffect(() => {
        (async () => {
            let errorObj = await fetch_Pg_6_1();
            handleCommonFetchErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_Pg_6_1();
        handleCommonFetchErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_6_1_Store, getPageData);
    const [amountLoaded, setAmountLoaded] = useState(20);
    const [selectedProduct, setSelectedProduct] = useState(false);

    const hasLoaded = pgData?.Root ?? false;

    const List_TokenEnw = hasLoaded ? pgData.Root.List_TokenEnw : undefined;
    const List_圖片生成 = hasLoaded ? pgData.Root.List_圖片生成 : undefined;
    const Item_會員公開 = hasLoaded ? pgData.Root.Item_會員公開 : undefined;
    const Item_會員 = hasLoaded ? pgData.Box_Header.Item_會員 : undefined;
    //#endregion

    const [errors, setErrors] = useState(false);

    const fields = useMyFields();
    let keyedFields = indexBy(fields, 'id');

    const navToPg_3_1 = () => {
        router.push("/tabs/3_1", "forward", "push");
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const ref_Item_圖片生成 = useRef(null);
    const ref_PhotoSet = useRef(null);

    //#region +Event
    const [presentWebModal, dismissWebModal] = useIonModal(Modal_3_1_WriteArticle, {
        dismiss: () => dismissWebModal(),
        //文章Id: postId,
        //set: (val) => setQRData(val),
        onResult: (result) => {
            if (result === true) {
                navToPg_3_1();
            }
        },

        reloadParent: reload,
        //product: selectedProduct

        Item_圖片生成: ref_Item_圖片生成.current,
        PhotoSet: ref_PhotoSet.current,
    });


    const startRePost = async (Item_圖片生成, PhotoSet) => {
        // const platforms = getPlatforms();
        // const isWeb = (platforms.includes("desktop") || platforms.includes("mobileweb") || platforms.includes("pwa"));
        //
        // if (!isWeb) {
        //     const data = await BarcodeScanner.scan();
        //     if (data) {
        //         handleSuccess(data);
        //     }
        // } else {
        //     presentWebModal({
        //         presentingElement: pageRef.current
        //     });
        // }

        ref_Item_圖片生成.current = Item_圖片生成;
        ref_PhotoSet.current = PhotoSet;

        presentWebModal({
            //cssClass: "product-modal",
            presentingElement: pageRef.current,
            initialBreakpoint: 0.50,
            breakpoints: [0, 0.25, 0.5, 0.75],
        });
    }

    //#endregion
    const _push_pg_6_1 = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const gatherPostData = async () => {
            const errors = validateForm(fields);
            setErrors(errors);

            if (!errors.length) {
                //  Submit your form here
                //const username = fields && (fields.filter(e => e.id === 'email')[0] as any).input.state.value;
                //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

                //const scodeEmail = fields && (keyedFields.scodeEmail as any).value;
                //const vcodeEmail = fields && (keyedFields.vcodeEmail as any).value;
                //const scodePhone = fields && (keyedFields.scodePhone as any).value;
                //const vcodePhone = fields && (keyedFields.vcodePhone as any).value;

                // let {email, scodeEmail, vcodeEmail} = Pg_B_1_Store.getRawState() as any;
                // let {phone, scodePhone, vcodePhone} = Pg_C_4_Store.getRawState() as any;
                //
                // if (emailVerifyStage !== 4 && emailVerifyStage !== 0) {
                //     alert("因為電子信箱改變過，請再次先完成Email驗證流程");
                //     return;
                // }
                // if (phoneVerifyStage !== 4 && phoneVerifyStage !== 0) {
                //     alert("因為手機號改變過，請再次先完成手機驗證流程");
                //     return;
                // }
                //

                const p1 = fields && (keyedFields.p1 as any).input.state.value;
                const p2 = fields && (keyedFields.p2 as any).input.state.value;
                const p3 = fields && (keyedFields.p3 as any).input.state.value;
                const p4 = fields && (keyedFields.p4 as any).input.state.value;

                // //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;
                // //const password = fields && (fields.filter(e => e.id === 'password')[0] as any).input.state.value;

                return {
                    p1,
                    p2,
                    p3,
                    p4,
                }
            } else {
                throw 'softError';
            }
        }


        try {
            let postData = await gatherPostData();

            if (await push_pg_6_1({
                //p1: Item_TokenEnw.TokenEnwId,
                ...postData,
            })) {
                alert('已送出處理要求，請稍後再刷新頁面查看')
                //reload();
            }

        } catch (err) {
            if (err != 'softError') {
                alert(err);
            }
        }

        //navToPg_2_3(router,Item_TokenEnw.TokenEnwId);
    }

    //#endregion

    return (
        <IonPage id={pageId}>
            <CustomPage name={pageId} sideMenu={false} sideMenuPosition="start" backButton={false}>
                <IonRefresher slot="fixed" onIonRefresh={reload}>
                    <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Pull to refresh"
                        refreshingSpinner="circles"
                        refreshingText="Refreshing...">
                    </IonRefresherContent>
                </IonRefresher>

                {!hasLoaded && <Fragment>
                    <>
                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            <div>
                                讀取中，讀取可能牽涉到AI計算，請稍後
                            </div>
                        </IonGrid>
                    </>
                </Fragment>}

                {hasLoaded && <Fragment>
                    <>
                        {!Item_會員 &&
                            <IonGrid>
                                <IonRow>
                                    <IonCol className="ion-justify-content-center ion-text-center w100 ion-padding">
                                        未登錄，登入後即可使用 AI生圖功能
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        }
                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom" >
                            <div className={styles_6_1.containerInner} onClickCapture={(ev)=> {
                                if (!hasAuthed) {
                                    promptBrowserToLogin();
                                    ev.stopPropagation();
                                    return false;
                                }
                                return true;
                            }}>
                                <h1 className={`${styles_TokenItem.productTitle} truncate`}>寵物AI生圖</h1>
                                {/*<h1 className={`${styles_TokenItem.productTitle} truncate`}>您今天還可以用AI生成 N 組照片</h1>*/}
                                <div className={styles_6_1.postBar}>
                                </div>
                                <IonRow>
                                    <IonCol>
                                        <CustomField alternateLeftZone={<>
                                            <IonSelect interfaceOptions={customSelectContainerOptions_您的寵物名字} interface="action-sheet" placeholder="您的寵物名字" onIonChange={(ev) => {
                                                keyedFields.p1.input.state.reset(ev.detail.value);
                                            }}
                                                       multiple={false} className={styles_Pg_C_1.select}>
                                                {List_TokenEnw.map((v) => {
                                                    return <IonSelectOption key={v.TokenEnwId} value={v.TokenEnwId}>{v.PetName}</IonSelectOption>
                                                })}
                                            </IonSelect>
                                        </>} field={keyedFields.p1} errors={errors} isHideField={true}/>
                                    </IonCol>
                                </IonRow>

                                <IonRow>
                                    <IonCol>
                                        <CustomField alternateLeftZone={<>
                                            <IonSelect interfaceOptions={customSelectContainerOptions_選取風格} interface="action-sheet" placeholder="選取風格" onIonChange={(ev) => {
                                                keyedFields.p2.input.state.reset(ev.detail.value);
                                            }}
                                                       multiple={false} className={styles_Pg_C_1.select}>
                                                {constStyleList.map((v) => {
                                                    return <IonSelectOption key={v.label} value={v.value}>{v.label}</IonSelectOption>
                                                })}
                                            </IonSelect>
                                        </>} field={keyedFields.p2} errors={errors} isHideField={true}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <CustomField field={keyedFields.p3} errors={errors}/>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <CustomField field={keyedFields.p4} errors={errors}/>
                                    </IonCol>
                                </IonRow>
                                {/*<div>AI生成後是否同步上傳到貼文</div>*/}
                                {/*<div><p>{capitalize(item.Name)}</p></div>*/}
                                <IonRow className="ion-margin-top ion-padding-top">
                                    <IonCol size="12">
                                        <IonButton className="custom-button" expand="block" onClick={_push_pg_6_1}>送出</IonButton>
                                    </IonCol>
                                </IonRow>
                            </div>
                        </IonGrid>


                        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
                            {/*<IonRow className={ styles.searchContainer }>*/}
                            {/*    <IonCol size="12">*/}
                            {/*        <IonSearchbar animated placeholder="Search for a product" />*/}
                            {/*    </IonCol>*/}
                            {/*</IonRow>*/}

                            {/*click={() => handleShowModal(item)}*/}
                            {List_圖片生成.map((item, index) => {
                                return <Fragment key={item.圖片生成Id}>
                                    <AiItem Item_圖片生成={item} Item_會員公開={Item_會員公開} DoRePost={startRePost}/>
                                </Fragment>
                            })}
                        </IonGrid>
                    </>
                </Fragment>}
            </CustomPage>
        </IonPage>
    );
};

export default Pg_6_1;

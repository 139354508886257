import {IonAvatar, IonBadge, IonButton, IonChip, IonCol, IonIcon, IonLabel, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./PostItem.module.scss";
import styles_Common from "../pages/Common.module.scss";
import {bagRemove, bagRemoveOutline, bookmark, bookmarkOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, remove, removeOutline, shareOutline, shareSocial, shareSocialOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import Ln_ContractPictureSetCoverImg from "../pages/Ln_ContractPictureSetCoverImg";
import {capitalize} from "../utils";
import {push_pg_3_11__AddArticleBookmark, push_pg_3_11__AddArticleFavor, push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveArticleBookmark, push_pg_3_11__RemoveArticleFavor} from "../store/Pg_3_Store";
import {useStoreState} from "pullstate";
import {Pg_1_1_Store} from "../store/Pg_1_Store";
import {getPageData} from "../store/Selectors";
import {UserPostBookmarkStore, UserPostFavorStore} from "../store/Common_Store";
import Ln_PostTime from "../pages/Ln_PostTime";
import {ApiService} from "../_/ApiService";
import React from "react";
import _ from "../_";
import {push_pg_6_1__Delete} from "../store/Pg_6_Store";


//#region +Event
// const navToPg_1_1 = () => {
//     router.push("/tabs/1_1", "forward", "push");
// }
//#endregion


export const AiItem = ({Item_圖片生成, Item_會員公開, fromHome = false, noLink = false, DoRePost = null}: {
    Item_圖片生成: any,
    Item_會員公開: any,
    fromHome?: any
    noLink?: any,
    DoRePost?: any
}) => {
    const router = useIonRouter();
    const postFavors = useStoreState(UserPostFavorStore, getPageData);
    const postBookmarks = useStoreState(UserPostBookmarkStore, getPageData);


    if (!Item_圖片生成) {
        return <>(ERROR:Item_圖片生成)</>;
    }

    if (!Item_會員公開) {
        return <>(ERROR:Item_圖片生成)</>;
    }

    // let Item_Token: any = Item_文章?._Token;
    // if (!Item_Token) {
    //     return <>(ERROR:Item_Token)</>;
    // }
    // let Item_Token_合約: any = Item_Token?._合約;
    // if (!Item_Token_合約) {
    //     return <>(ERROR:Item_Token_合約)</>;
    // }
    // let Item_Token_會員公開: any = Item_文章?._會員公開;
    // if (!Item_Token_會員公開) {
    //     return <>(ERROR:Item_Token_會員公開)</>;
    // }

    const navToPg_3_11_ByUser = (userId) => {
        router.push(`/tabs/3_1?userId=${userId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    const navToPg_3_11_ByContract = (contractId) => {
        router.push(`/tabs/3_1?contractId=${contractId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const _push_pg_6_1__Delete = async (圖片生成Id) => {
        if (await push_pg_6_1__Delete({
            p1: 圖片生成Id,
        })) {
            //navToPg_1_1();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }

    let PhotoJsx: any = null;

    let isMoreThanOneImage = false;
    //debugger;

    if (Item_圖片生成.PhotoSet) {
        let photos = _._b.getCoverSafeList(Item_圖片生成.PhotoSet);
        if (photos.length > 1) {
            isMoreThanOneImage = true;
        }
        console.dir([photos]);


        PhotoJsx = <React.Fragment>
            <div className={styles.postBar}>
                <a href={photos?.[0] ?? "#"}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_圖片生成.PhotoSet,
                        名稱: '照片',
                    })}
                    {/*<img loading="lazy"*/}
                    {/*     src={Item_圖片生成.url}*/}
                    {/*/>*/}
                </a>

                {photos.length > 1 &&
                    <div className={styles.belowImage}>
                        <a href={photos?.[1] ?? "#"}>
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: Item_圖片生成.PhotoSet,
                                名稱: '照片',
                                imageAtIndex: 1,
                            })}
                            {/*<img loading="lazy"*/}
                            {/*     src={Item_圖片生成.url}*/}
                            {/*/>*/}
                        </a>
                        {
                            <div className={styles.belowImageBar}>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    DoRePost?.(Item_圖片生成, photos?.[1]);
                                    //_push_pg_3_11__Share(Item_文章);

                                    return false;
                                }}><IonLabel>
                                    轉貼
                                </IonLabel><IonIcon icon={shareSocialOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </div>
                        }
                    </div>
                }

                {photos.length > 2 &&
                    <div className={styles.belowImage}>
                        <a href={photos?.[2] ?? "#"}>
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: Item_圖片生成.PhotoSet,
                                名稱: '照片',
                                imageAtIndex: 2,
                            })}
                            {/*<img loading="lazy"*/}
                            {/*     src={Item_圖片生成.url}*/}
                            {/*/>*/}
                        </a>
                        {
                            <div className={styles.belowImageBar}>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    DoRePost?.(Item_圖片生成, photos?.[2]);
                                    //_push_pg_3_11__Share(Item_文章);

                                    return false;
                                }}><IonLabel>
                                    轉貼
                                </IonLabel><IonIcon icon={shareSocialOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </div>
                        }
                    </div>
                }

                {photos.length > 3 &&
                    <div className={styles.belowImage}>
                        <a href={photos?.[3] ?? "#"}>
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: Item_圖片生成.PhotoSet,
                                名稱: '照片',
                                imageAtIndex: 3,
                            })}
                            {/*<img loading="lazy"*/}
                            {/*     src={Item_圖片生成.url}*/}
                            {/*/>*/}
                        </a>
                        {
                            <div className={styles.belowImageBar}>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    DoRePost?.(Item_圖片生成, photos?.[3]);
                                    //_push_pg_3_11__Share(Item_文章);

                                    return false;
                                }}><IonLabel>
                                    轉貼
                                </IonLabel><IonIcon icon={shareSocialOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </div>
                        }
                    </div>
                }

                {photos.length > 4 &&
                    <div className={styles.belowImage}>
                        <a href={photos?.[4] ?? "#"}>
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: Item_圖片生成.PhotoSet,
                                名稱: '照片',
                                imageAtIndex: 4,
                            })}
                            {/*<img loading="lazy"*/}
                            {/*     src={Item_圖片生成.url}*/}
                            {/*/>*/}
                        </a>
                        {
                            <div className={styles.belowImageBar}>
                                <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    DoRePost?.(Item_圖片生成, photos?.[4]);
                                    //_push_pg_3_11__Share(Item_文章);

                                    return false;
                                }}><IonLabel>
                                    轉貼
                                </IonLabel><IonIcon icon={shareSocialOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                </IonButton>
                            </div>
                        }
                    </div>
                }
            </div>

        </React.Fragment>;
    } else if (Item_圖片生成.url) {
        PhotoJsx = <div className={styles.postBar}>
            <a href={Item_圖片生成.url}>
                <img loading="lazy"
                     src={Item_圖片生成.url}
                />
            </a>
        </div>;
    }

    //"pending" | "in-progress" | "completed" | "failed",

    let Status: any = null;

    //debugger;
    if (Item_圖片生成.status) {
        if (Item_圖片生成.status === "pending") {
            Status = <div className="r2">
                <div className="roundbg">
                    檢查中
                </div>
            </div>;
        } else if (Item_圖片生成.status === "in-progress") {
            Status = <div className="r2">
                <div className="roundbg">
                    進行中 {Item_圖片生成.progress}
                </div>
            </div>;
        } else if (Item_圖片生成.status === "completed") {
            if (Item_圖片生成.IsProcessedAfterComplete) {
                Status = <div className="r2">
                    <div className="roundbg">
                        完成
                    </div>
                </div>;
            } else {
                Status = <div className="r2">
                    <div className="roundbg">
                        快了
                    </div>
                </div>;
            }
        } else if (Item_圖片生成.status === "failed") {
            Status = <div className="r2">
                <div className="roundbg">
                    失敗
                </div>
            </div>;
        }
    } else {
        Status = <div className="r2">
            <div className="roundbg">
                未知
            </div>
        </div>
    }


    return (
        <div className={styles.containerInner}>
            <div className={styles.nameBar}>
                <div className="container">
                    <div className="l" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navToPg_3_11_ByUser(Item_會員公開.會員Id);

                        return false;
                    }}>
                        <IonAvatar className="avatar">
                            {Ln_TokenPictureSetCoverImg({
                                圖片組: Item_圖片生成._TokenEnw.PetPhoto,
                                //Item_會員公開.個人照片,
                                名稱: 'cover',
                                imageAtIndex: 0,
                            })}
                        </IonAvatar>
                    </div>
                    <div className="r" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        navToPg_3_11_ByUser(Item_會員公開.會員Id);

                        return false;
                    }}>
                        {/*{Item_會員公開.暱稱}*/}
                        {Item_圖片生成._TokenEnw.PetName}
                    </div>

                    <div className="r2">
                        <div className="roundbg">
                            <div className="dt">
                                <Ln_PostTime
                                    msTimestamp={Item_圖片生成.CreatedAt}/>
                            </div>

                            {/*{Item_Token_合約.Name}*/}
                        </div>
                    </div>
                    {Status}


                    {/*<div className="r2">*/}
                    {/*    <div className="roundbg">*/}
                    {/*        完成*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="r2">*/}
                    {/*    <div className="roundbg">*/}
                    {/*        生成中*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
                {PhotoJsx}
                {/*<div className={styles.postBar}>*/}
                {/*    {Ln_TokenPictureSetCoverImg({*/}
                {/*        圖片組: Item_文章.封面組,*/}
                {/*        名稱: 'cover',*/}
                {/*    })}*/}
                {/*</div>*/}
                <div className={styles.textBar}>
                    {Item_圖片生成.風格},{Item_圖片生成.做什麼},{Item_圖片生成.在哪裡}
                </div>


                <div className={styles.actionBar}>
                    {
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                if (window.confirm('刪除指定圖片，將不可復原')) {
                                    _push_pg_6_1__Delete(Item_圖片生成.圖片生成Id);
                                }

                                return false;
                            }}><IonLabel>
                                刪除
                            </IonLabel><IonIcon icon={bagRemoveOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }
                    {!isMoreThanOneImage &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                DoRePost?.(Item_圖片生成, null);
                                //_push_pg_3_11__Share(Item_文章);

                                return false;
                            }}><IonLabel>
                                轉貼
                            </IonLabel><IonIcon icon={shareSocialOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
